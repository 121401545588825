<template>
  <div class="confirm" v-show="isShow">
    <div class="box">
      <div class="title">
        <span>{{title}}</span>
        <div class="close"
          v-show="closeBtn"
          @click="hide"></div>
      </div>
      <div class="content" v-html="content"></div>
      <div class="btn-box">
        <button
          v-for="(b, index) in btn" :key="index"
          :class="{ 'btn-o': index === 0 && btn.length > 1 }"
          @click="clickBtn(index)">{{b}}</button>
      </div>
    </div>
    <div class="shade"
      :style="{ opacity: shade }"
      @click="close"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: false,
      title: '提示',
      closeBtn: false,
      shade: 0.6,
      shadeClose: false,
      content: '提示框内容',
      btn: ['确定'],
      fun: {}
    }
  },
  methods: {
    open: function (title = '提示', opt, ...fun) {
      this.title = title
      this.closeBtn = opt.closeBtn ? opt.closeBtn : this.closeBtn
      this.shade = opt.shade ? opt.shade : this.shade
      this.shadeClose = opt.shadeClose ? opt.shadeClose : this.shadeClose
      this.content = opt.content ? opt.content : this.content
      this.btn = opt.btn ? opt.btn : this.btn
      this.fun = fun
      this.isShow = true
    },
    hide: function () {
      this.isShow = false
    },
    close: function () {
      if (this.option.shadeClose) {
        this.isShow = false
      }
    },
    clickBtn: function (index) {
      this.fun[index]()
    }
  }
}
</script>

<style lang="less" scoped>
.shade{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background-color: #000;
}
.confirm{
  
}
.confirm .box{
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: 20px;
}
.confirm .title{
  padding: 10px 20px;
  background: #102b6a;
  font-size: 0;
  position: relative;
}
.confirm .title span{
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
}
.confirm .close{
  width: 100px;
  height: 70px;
  position: absolute;
  top: 50%;
  right: 0;
  background-image: url(../assets/icon/icon_close.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px;
  transform: translate(0, -50%);
}
.content{
  padding: 30px 20px;
  color: #333;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;
  overflow: hidden;
}
.confirm .btn-box{
  text-align: center;
  font-size: 0;
  margin: 0;
}
.confirm .btn-box button{
    color: #fff;
    font-size: 16px;
    line-height: 1;
    padding: 10px 24px;
    margin: 0 10px;
    border: solid #ffc065 1px; /*no*/
    border-radius: 2px;
    background-color: #102b6a;
    outline: medium;
    cursor: pointer;
  }
.confirm .btn-box .btn-o{
  color: #102b6a;
  background-color: #fff;
}
</style>
